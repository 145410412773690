import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


export default function WhoTemplate() {
  return (
    <Layout breadcrumbs={["Our Members"]}>
    <div className="carousel-container">
      <div className="group-5-about-us">
      <div className="connecting-the-blockchain-together">Our Members</div>
      <p className="about-us-carousel">We are proud to be a nonprofit organization, funded entirely by our members, sponsors, and partners. It’s simple: we all chip in because we all benefit.<br /><br />In addition to our individual, corporate, and student members, these are the companies and organizations that make our good work possible.</p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705702247/our-members/bba-our-members-header_gcfaox.jpg" className="group-3" />
    </div>  
	<div className="our-members-container">
	<div className="our-members-title">Sponsors</div>
	<div className="our-members-subtitle">Over 3,500 participants from the world’s leading companies:</div>
	<div className="our-members-title">Platinum</div>
	<div className="partner-sponsor-carousel">
		<a href="https://ripple.com/" target="_blank"><img className="our-members-sponsors"  src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731767048/our-members/Platinum_Ripple_Small_qas3uv.png" /></a>
	</div>
		<div className="our-members-title">Gold</div>
	<div className="partner-sponsor-carousel">	
		<a href="https://www.aon.com/en/" target="_blank"><img className="our-members-sponsors"  src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731767606/our-members/Gold_AON_small_hsj1f5.png" /></a>	
		<a href="https://www2.deloitte.com/us/en.html" target="_blank"><img className="our-members-sponsors"  src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731767151/our-members/Gold_Deloitte_small_xyw2l6.jpg" /></a>	
	 	<a href="https://www.fidelity.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731952472/our-members/Gold_Fid_Logo_Blk-small-245_np7ajc.png" /></a>
	 	<a href="https://www.fidelitydigitalassets.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731952570/our-members/Gold_2024_FDA_-_Logo_Large_Black_Green_CMYK-small-252_rrbibs.png" /></a>
	 	<a href="https://www.fcatalyst.com/overview" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731952648/our-members/Gold_FCAT_small_noze2c.jpg" /></a>
	</div>
		<div className="our-members-title">Silver</div>
	<div className="partner-sponsor-carousel">
	         <a href="https://algorandtechnologies.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743717/branding/new%20logos/algorand-foudation-logo_vii5eg.png" /></a>
  			<a href="https://www.ropesgray.com/en" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743710/branding/new%20logos/ropes-and-gray_aalhol.png" /></a>	
         <a href="https://www.amberdata.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1726251833/our-members/thumbnail_amberdata_logo_color_1_2_n0kgiq.png" /></a>
</div>
	<div className="our-members-title">Business Partner</div>
	<div className="partner-sponsor-carousel">
			<a href="https://solana.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731967588/our-members/Business_Partner_Solana_small_mtez11.jpg" /></a>	
			<a href="https://mediashower.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743787/branding/new%20logos/mediashower_bqbswj.png" /></a>	
			<a href="https://thecryptorecruiters.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731967722/our-members/Business_Partner_the_crypto_recruiters_linkedin_banner_1_-small-207_zvkx68.png" /></a>	
			<a href="https://block-health.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1731967900/our-members/Business_Partner_BlockHealth-Logo-small-236_yrgf3y.jpg" /></a>	
</div>
	<div className="our-members-title">Startup</div>
<div className="partner-sponsor-carousel">
		<a href="https://dvlpmedicines.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732048811/our-members/Startup_DVLP_small_p64rfy.png" /></a>	
		<a href="https://www.fyeo.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049029/our-members/Startup_FYEO_small_ghwtkv.png" /></a>
		<a href="https://influxtech.ai/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049112/our-members/Startup_InFlux-Logo-Black-small-117_cikc2i.png" /></a>
		<a href="https://www.kadena.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049195/our-members/Startup_Kadena_-_Dark_-_Two_Color_2_-small-106_qp79tw.png" /></a>
		<a href="https://true-tickets.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049072/our-members/Startup_True_Tickets_small_om7lav.png" /></a>
		<a href="https://provenance.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049073/our-members/Startup_Provenance_small_feqb4c.png" /></a>
</div>
	<div className="our-members-title">Community Partners</div>
		<div className="partner-sponsor-carousel">
			    <a href="https://masstech.org/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049257/our-members/Community_Partner_Mass_Tech_small_cmjbh9.png" /></a>		
				<a href="https://www.bostonfed.org/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1706643673/our-members/federal-reserve_rxfwvt_d8ljdy.png" /></a>				
				<a href="https://www.neach.org/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049343/our-members/Community_Partner_NEACH_small_mggzef.jpg" /></a>				
				<a href="https://fintechwomenusa.com/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049401/our-members/Community_Partner_FinTechWomen_Logo_3_-small-157_n8v9wi.jpg" /></a>				
				<a href="https://www.tabbgroup.com/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049452/our-members/Community_Partner_TABBGroup-297_jcm2ch.jpg" /></a>				
				<a href="https://www.suffolk.edu/business" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1732049529/our-members/Community_Partner_Suffolk_SBS_CenterEntrepreneurship_SS_LeftStacked_2767_8642-small-177_ouinem.png" /></a>
		</div>
	</div>
	    <div className="container-noflex">
        <div className="our-members-cta-new">
          <div className="our-members-content-new">
            <h2>Become a Member</h2>
            <p>All BBA members get access to exclusive in-person events, online workshops, and professional development opportunities.<br /><br />Corporate members get access to our community, our talent pipeline, and our enterprise innovation hub.</p>
            <a href="/become-a-member"><button className="who-we-are-new-volunteer-button">JOIN THE BBA</button></a>
          </div>
          <div className="our-members-img">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1705621199/our-members/bba-our-members-bottom_yynraw.jpg" />
          </div>          
         </div>  
    </div>  
      </Layout>
  )
}